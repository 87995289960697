<template>
  <div style="margin-top: 40px" v-show="list.sub_list" v-loading="loading">
    <div class="header">
      <div class="textNew">按科目学习</div>
      <div class="text">从零起步到完整备考</div>
    </div>
    <div class="content">
      <div class="boxCenter">
        <div class="box">
          <img
            class="imgIcon"
            width="71"
            height="110"
            src="../../../../assets/live/ly.png"
          />
          <div class="text">{{ list.sub_list && list.sub_list[0].name }}</div>
          <div class="text2">共{{ list.sort }}课时</div>
          <el-button
            class="btn"
            type="primary"
            round
            @click="submit(list.sub_list[0])"
            >免费试听</el-button
          >
        </div>
        <div class="box">
          <img
            class="imgIcon"
            width="120"
            height="106"
            src="../../../../assets/live/x.png"
          />
          <div class="text">{{ list.sub_list && list.sub_list[1].name }}</div>
          <div class="text2">共{{ list.sort }}课时</div>
          <el-button
            class="btn"
            type="primary"
            round
            @click="submit(list.sub_list[1])"
            >免费试听</el-button
          >
        </div>
        <div class="box">
          <img
            class="imgIcon"
            width="83"
            height="102"
            src="../../../../assets/live/s.png"
          />
          <div class="text">{{ list.sub_list && list.sub_list[2].name }}</div>
          <div class="text2">共{{ list.sort }}课时</div>
          <el-button
            class="btn"
            type="primary"
            round
            @click="submit(list.sub_list[2])"
            >免费试听</el-button
          >
        </div>
        <div class="box">
          <img
            class="imgIcon"
            width="119"
            height="97"
            src="../../../../assets/live/rj.png"
          />
          <div class="text">{{ list.sub_list && list.sub_list[3].name }}</div>
          <div class="text2">共{{ list.sort }}课时</div>
          <el-button
            class="btn"
            type="primary"
            round
            @click="submit(list.sub_list[3])"
            >免费试听</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiCourseCate } from '@/api/IELTScourse'

export default {
  data() {
    return {
      list: [],
      loading: false
    }
  },
  created() {
    this.fetchList()
  },
  methods: {
    submit(v) {
      this.$router.push({
        name: 'IELTScourse',
        query: {
          cateMain: v.pid,
          cateSub: v.id
        }
      })
    },
    async fetchList() {
      this.loading = true
      const { data } = await apiCourseCate()
      if (data.length) {
        this.loading = false
        this.list = data[1]
      }
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  display: flex;
  .textNew {
    font-size: 26px;

    font-weight: bold;
    color: #1f1f40;
  }
  .text {
    font-size: 16px;

    font-weight: 400;
    line-height: 19px;
    color: #3a3a3a;
    margin-left: 12px;
    line-height: 37px;
  }
}
.content {
  .boxCenter {
    display: flex;
    .box {
      width: 252px;
      height: 156px;
      background: url('../../../../assets/live/04.png') no-repeat;
      position: relative;
      transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
      &:hover {
        transform: translateY(-8px);
      }
      .imgIcon {
        position: absolute;
        left: 23px;
        top: 29px;
        pointer-events: none;
      }
      .text {
        font-size: 18px;

        font-weight: bold;
        color: #1f1f40;
        position: absolute;
        top: 40px;
        left: 42px;
      }
      .text2 {
        font-size: 12px;

        font-weight: 400;
        color: #1f1f40;
        position: absolute;
        top: 40px;
        left: 119px;
      }
      .btn {
        width: 170px;
        height: 28px;
        background: #f7a437;
        opacity: 1;
        border-radius: 14px;
        font-size: 14px;

        font-weight: 400;
        color: #ffffff;
        border-color: #f7a437;
        line-height: 1px;
        margin-top: 91px;
        margin-left: 41px;
      }
    }
  }
}
</style>
