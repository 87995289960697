<template>
  <div style="margin-top: 40px">
    <div class="header">
      <div class="textNew">
        口语
        <div></div>
        写作题库
      </div>
      <div class="text">高频+新题第一时间掌握</div>
    </div>
    <div class="boxShow">
      <div class="boxContent">
        <div class="one">
          <img src="../../../../assets/live/002.png" alt="" />
          <div class="numberText">
            <div>1</div>
            <div>2</div>
            <div>3</div>
          </div>
          <div class="title">
            最新口语写作高频真题<br />
            详细使用讲解视频<br />
            最全题库文档下载
          </div>
        </div>
        <div class="two">
          <div class="title">口语题库</div>
          <div class="content">
            <div class="text">
              口语新题及近期高频<br />
              详解视频+文档下载
            </div>
            <!-- <img class="img" src="../../../../assets/live/09.png" alt="" /> -->
            <el-button class="btn" type="primary" round @click="toCourse('1')"
              >查看</el-button
            >
          </div>
        </div>
        <div class="two">
          <div class="title">写作题库</div>
          <div class="content">
            <div class="text">
              写作新题及近期高频<br />
              详解视频+文档下载
            </div>
            <!-- <img class="img2" src="../../../../assets/live/10.png" alt="" /> -->
            <el-button class="btn" type="primary" round @click="toCourse('2')"
              >查看</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {
    toCourse(v) {
      if (v == '2') {
        this.$router.replace({
          name: 'IELTScourse',
          query: {
            ...this.$route.query,
            cateMain: 102,
            cateSub: 122
          }
        })
      } else {
        this.$router.replace({
          name: 'IELTScourse',
          query: {
            ...this.$route.query,
            cateMain: 102,
            cateSub: 121
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.boxShow {
  width: 952px;
  height: 146px;
  background: #f5f6fa;
  border-radius: 10px;
  position: relative;
  .boxContent {
    display: flex;
    position: absolute;
    bottom: 20px;
    .one {
      width: 286px;
      height: 145px;
      background: #ffc06a;
      border-radius: 10px;
      display: flex;
      margin-left: 20px;
      img {
        position: absolute;
      }
      .numberText {
        padding-top: 17px;
        padding-left: 33px;
        position: relative;
        div {
          width: 25px;
          height: 25px;
          background: #1f1f40;
          border-radius: 50%;
          text-align: center;
          line-height: 25px;
          margin: 9px;
          color: #ffc06a;
        }
      }
      .title {
        width: 176px;
        height: 124px;
        font-size: 16px;

        font-weight: 400;
        line-height: 34px;
        color: #1f1f40;
        padding-top: 21px;
      }
    }

    .two {
      width: 286px;
      height: 145px;
      border-radius: 10px;
      text-align: center;
      background: #ffffff;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
      margin-left: 28px;
      transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
      &:hover {
        transform: translateY(-8px);
      }
      .title {
        height: 42px;
        background: #ffc06a;
        border-radius: 10px 10px 0px 0px;
        line-height: 42px;
        font-size: 16px;
        color: #1f1f40;
      }
      .content {
        font-size: 14px;

        font-weight: 400;
        line-height: 22px;
        color: #1f1f40;
        padding-top: 10px;
        position: relative;
        .img {
          position: absolute;
          bottom: -19px;
          right: 3px;
          width: 106px;
          height: 118px;
        }
        .img2 {
          position: absolute;
          bottom: -19px;
          right: 3px;
          width: 107px;
          height: 113px;
        }
        .btn {
          width: 130px;
          height: 28px;
          background: #3b86ff;
          opacity: 1;
          border-radius: 14px;
          font-size: 14px;

          font-weight: 400;
          color: #ffffff;
          border-color: #3b86ff;
          line-height: 1px;
          margin-top: 6px;
        }
      }
    }
  }
}
.header {
  display: flex;
  margin-bottom: 40px;
  .textNew {
    font-size: 26px;

    font-weight: bold;
    color: #1f1f40;
    div {
      width: 7px;
      height: 7px;
      background: #1f1f40;
      border-radius: 50%;
      display: inline-block;
      margin-bottom: 5px;
    }
  }
  .text {
    font-size: 16px;

    font-weight: 400;
    line-height: 19px;
    color: #3a3a3a;
    margin-left: 12px;
    line-height: 37px;
  }
}
</style>
