<template>
  <div class="page-wrapper">
    <Live />
    <New />
    <Study />
    <KY />
    <XZ />
  </div>
</template>

<script lang="js">
import Live from './liveTwo'
import New from './newIntroduction'
import Study from './study'
import KY from './ky'
import XZ from './xz'



export default {
  components:{
    Live,
    New,
    Study,
    KY,
    XZ
  },
}
</script>

<style lang="less" scoped>
.page-wrapper {
  width: 1026px;
  .ad-img {
    > img {
      width: 100%;
      border-radius: 5px;
    }
  }
}
</style>
