<template>
  <div class="radio-class">
    <div class="banerText" v-if="list.length">
      <div class="homeText">{{ list.length && list[0].cate_main_name }}</div>
      <span class="iconfont icon homeText">&#xe76d;</span>
      <div class="userInfoText">
        {{ list.length && list[0].cate_sub_name }}
      </div>
    </div>
    <div
      class="live-com"
      v-if="
        configHeader['高频题库'] &&
          configHeader['高频题库'][0] &&
          courseRes.length
      "
      v-loading="loading"
    >
      <div class="header">
        <div class="headerText">
          {{ configHeader['高频题库'][0].cate_3_name }}
        </div>
        <div class="gdText" @click="toBank()">
          <span class="iconfont">&#xe76d;</span>更多
        </div>
      </div>
      <el-row id="loding" gutter="38">
        <el-col :md="12" :sm="24">
          <div class="live-left">
            下载题库必看
            <div class="box">
              <img
                width="181"
                height="98"
                :src="configHeader['高频题库'][0].img_logo"
                alt=""
              />
              <div
                class="zbText"
                v-if="configHeader['高频题库'][0].cate_main === 3"
              >
                直播
              </div>
              <div
                class="zbText2"
                v-else-if="
                  configHeader['高频题库'][0].money == 0 &&
                    configHeader['高频题库'][0].is_vip !== 1
                "
              >
                免费
              </div>
              <div class="titleText">
                {{ configHeader['高频题库'][0].name }}
              </div>
              <div class="labe">
                <Label :label="configHeader['高频题库'][0].label_id" />
              </div>
              <a
                href="javascript:;"
                @click="toDetail(configHeader['高频题库'][0])"
              >
                <i class="el-icon-arrow-right"></i>
              </a>
            </div>
          </div>
        </el-col>
        <el-col :md="12" :sm="24">
          <div class="rightBox" v-loading="courseResLoading">
            <div class="title">最新高频</div>
            <div
              class="textHeader"
              v-for="(item, index) in courseRes"
              :key="index"
            >
              <svg
                t="1623123691229"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="4806"
                width="16"
                height="16"
              >
                <path
                  d="M512 513.4m-192 0a192 192 0 1 0 384 0 192 192 0 1 0-384 0Z"
                  fill="#5B9EFF"
                  p-id="4807"
                ></path>
              </svg>
              <div>{{ item.name }}</div>
              <a :href="item.res_url" download="下载" :filename="item.res_url">
                <svg
                  t="1623123913141"
                  class="icon2"
                  viewBox="0 0 1117 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="7013"
                  width="20"
                  height="20"
                >
                  <path
                    d="M243.47623678 694.98673879h573.0785332v69.42559869H243.47623678V694.98673879z m444.13830441-199.87932483L561.31072734 613.74609525a46.52784466 46.52784466 0 0 1-31.34404623 11.76622306 45.60021729 45.60021729 0 0 1-31.24640163-11.86386814L372.31882058 495.20505901a33.19930156 33.19930156 0 0 1-10.83859567-23.77655828c0-19.62664573 18.89430857-35.54278178 42.18264233-35.54278183h0.97644998v-0.19529022h83.29118919V348.49343921c0-19.62664573 18.84548598-35.54278178 42.13381929-35.54278132 23.23951129 0 42.13381981 15.8673131 42.13381986 35.54278132v87.19698947h81.77769162v0.29293534l2.4411249-0.09764512c23.28833389 0 42.18264186 15.91613557 42.18264234 35.54278183a32.56460892 32.56460892 0 0 1-10.9362407 23.72773576zM462.59162759 243.47623678H597.43937924v208.37444024H462.59162759V243.47623678z"
                    fill="#cdcdcd"
                    p-id="7014"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div>
      <div v-if="config.length">
        <div class="content" v-for="(item, index) in config" :key="index">
          <div class="header">
            <div class="headerText">
              {{
                item[index] &&
                  item[index].cate_3_name &&
                  item[index].cate_3_name
              }}
            </div>
            <div
              class="gdText"
              v-if="
                item[index] &&
                  item[index].cate_3_name &&
                  item[index].cate_3_name
              "
              @click="toCardItem(item)"
            >
              <span class="iconfont">&#xe76d;</span>更多
            </div>
          </div>
          <ul v-if="list && list.length" v-loading="loading">
            <Component
              :is="comName"
              v-for="val in item"
              :key="val.id"
              :info="val"
            />
          </ul>
          <Empty v-else />
        </div>
      </div>
      <div class="content" v-else>
        <ul v-if="list && list.length" v-loading="loading">
          <Component
            :is="comName"
            v-for="item in list"
            :key="item.id"
            :info="item"
          />
        </ul>
        <Empty v-else />
      </div>
    </div>
  </div>
</template>

<script>
import CardItem from '../../components/cardItem'
import TryItem from '../../components/tryItem'
import Empty from '@/components/Empty'
import { apiCourseList, courseResList } from '@/api/IELTScourse'
import _ from 'lodash'
const TRY_CATE_SUB = [21, 22, 23, 24]

export default {
  components: {
    CardItem,
    TryItem,
    Empty
  },
  props: {
    onList: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      loading: false,
      courseResLoading: false,
      list: [],
      comName: '',
      routeId: '',
      config: [],
      configHeader: [],
      courseRes: []
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.fetchList()
        this.routeId = this.$route.query.cateMain

        this.courseResList()
      }
    },
    list() {
      this.onList(this.list)
    }
  },
  mounted() {},
  methods: {
    setComName() {
      const { cateSub } = this.$route.query
      if (TRY_CATE_SUB.includes(Number(cateSub))) {
        this.comName = 'TryItem'
      } else {
        this.comName = 'CardItem'
      }
    },
    async courseResList() {
      this.courseResLoading = true
      const { cateMain, cateSub } = this.$route.query
      courseResList({
        cate_main: cateMain,
        cate_sub: cateSub,
        page: 1,
        order_by: 'sort',
        page_size: 3
      }).then((res) => {
        if (res.code == 200) {
          this.courseRes = res.data.list
          this.courseResLoading = false
        }
      })
    },
    toBank() {
      const { cateMain, cateSub } = this.$route.query
      this.$router.push({
        name: 'IELTScourseBank',
        query: {
          cateMain: cateMain,
          cateSub: cateSub
        }
      })
    },
    toDetail(val) {
      this.$router.push({
        name: 'IELTSchapterDetail',
        query: {
          skillId: val.id,
          type: 1
        }
      })
    },
    toCardItem(v) {
      const { cateMain, cateSub } = this.$route.query
      this.$router.push({
        name: 'IELTScourseCardItem',
        query: {
          cateMain: cateMain,
          cateSub: cateSub,
          cate_3: v[0].cate_3
        }
      })
    },
    async fetchList() {
      this.config = []
      this.loading = true
      const { cateMain, cateSub } = this.$route.query
      const { data } = await apiCourseList({
        cate_main: cateMain,
        cate_sub: cateSub,
        page: 1,
        page_size: 99999
      })
      const arrList = _.cloneDeep(data.list)
      const config = _.groupBy(arrList, (item) => {
        return item.cate_3_name
      })
      this.configHeader = _.cloneDeep(config)
      if (_.has(config, '高频题库')) {
        Object.keys(_.omit(config, ['高频题库'])).map((item) => {
          this.config.push(_.slice(config[item], 0, 4))
        })
      } else if (!_.isEmpty(config)) {
        Object.keys(config).map((item) => {
          if (!_.isEmpty(config[item][0].cate_3_name)) {
            this.config.push(_.slice(config[item], 0, 4))
          }
        })
      }
      this.setComName()
      this.list = data.list
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.radio-class {
  width: 100%;
  .live-com {
    .header {
      display: flex;
      justify-content: space-between;
      .headerText {
        font-size: 18px;
        font-weight: bold;
        color: #1f1f40;
        margin-top: 9px;
        margin-bottom: 10px;
      }
      .gdText {
        font-size: 14px;
        font-weight: 400;
        color: #9c9c9c;
        margin-top: 13px;
        cursor: pointer;
        margin-right: 39px;
      }
    }

    .live-left {
      width: 460px;
      height: 181px;
      background: #f5f6fa;
      border-radius: 10px;
      box-sizing: border-box;
      font-size: 16px;

      font-weight: bold;
      line-height: 19px;
      color: #1f1f40;
      padding: 17px 27px;
      .box {
        width: 462px;
        height: 175px;
        position: relative;
        background: url('../../../../assets/live/02.png') no-repeat;
        box-sizing: border-box;
        margin-left: -13px;
        margin-top: -13px;
        img {
          margin-top: 35px;
          margin-left: 39px;
        }
        .teachImg {
          position: absolute;
          top: -63px;
          left: 54px;
          width: 83px;
        }
        .zbText {
          position: absolute;
          width: 38px;
          background: #ffffff;
          border-radius: 10px;
          font-size: 10px;
          color: #f44336;
          padding: 1px;
          top: 42px;
          left: 176px;
          div {
            width: 5px;
            height: 5px;
            background-color: #f44336;
            border-radius: 50%;
            display: inline-block;
            margin-bottom: 2px;
            margin-left: 3px;
          }
        }
        .zbText2 {
          position: absolute;
          width: 38px;
          background: #ffffff;
          border-radius: 10px;
          font-size: 10px;
          text-align: center;
          color: #f44336;
          padding: 1px;
          top: 42px;
          left: 176px;
        }
        .titleText {
          position: absolute;
          left: 237px;
          top: 31px;
          width: 138px;
          font-size: 14px;
          font-weight: bold;
          color: #1f1f40;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .labe {
          position: absolute;
          width: 138px;
          height: 29px;
          border-bottom: 1px solid #ebebf2;
          bottom: 64px;
          left: 234px;
        }
        .el-icon-arrow-right {
          position: absolute;
          right: 89px;
          bottom: 43px;
          color: #1f1f40;
        }
      }
    }
    .rightBox {
      box-sizing: border-box;
      width: 460px;
      height: 181px;
      background: #f5f6fa;
      border-radius: 10px;
      padding-top: 17px;
      padding-left: 29px;
      padding-right: 24px;
      .title {
        font-size: 16px;
        font-weight: bold;
        color: #1f1f40;
      }
      .textHeader {
        display: flex;
        margin-top: 17px;
        position: relative;
        .icon {
          margin-top: 3px;
        }
        div {
          margin-left: 7px;
          font-size: 14px;
          font-weight: 400;
          color: #1f1f40;
        }
        .icon2 {
          position: absolute;
          right: 0;
          cursor: pointer;
        }
      }
    }
  }
  .banerText {
    margin-bottom: 16px;
    margin-top: 3px;
    display: flex;
    font-size: 14px;

    font-weight: 400;
    line-height: 17px;
    color: #1f1f40;
    .homeText {
      color: #9c9c9c;
      font-size: 14px;
    }
    .icon {
      margin-left: 19px;
      margin-right: 19px;
      font-weight: 700;
    }
    .userInfoText {
      font-size: 14px;

      font-weight: 400;
      color: #1f1f40;
    }
  }
  .content {
    .header {
      display: flex;
      justify-content: space-between;
      .headerText {
        font-size: 18px;
        font-weight: bold;
        color: #1f1f40;
        margin-top: 9px;
        margin-bottom: 10px;
      }
      .gdText {
        font-size: 14px;
        font-weight: 400;
        color: #9c9c9c;
        margin-top: 13px;
        cursor: pointer;
        margin-right: 39px;
      }
    }
    > ul {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -12px;
    }
  }

  .radio-class-title {
    font-size: 20px;
    font-weight: bold;
    color: #1f1f40;
  }
  .radio-class-goback-title {
    font-size: 20px;
    font-weight: bold;
    color: #1f1f40;
    margin-top: 40px;
  }
}
</style>
