<template>
  <div style="margin-top: 20px; margin-bottom: 74px">
    <div class="header">
      <div class="textNew">写作专区</div>
    </div>
    <div class="boxShow">
      <div class="boxContent">
        <div class="two">
          <div class="title">技巧讲解</div>
          <div class="content">
            <div class="text">
              大作文 / 小作文<br />
              写作技巧一网打尽
            </div>
            <el-button class="btn" type="primary" round @click="toCourse('1')"
              >查看</el-button
            >
          </div>
        </div>
        <div class="two">
          <div class="title">范文讲解</div>
          <div class="content">
            <div class="text">
              完整真题解题思路<br />
              及完整范文展示
            </div>
            <el-button class="btn" type="primary" round @click="toCourse('2')"
              >查看</el-button
            >
          </div>
        </div>
      </div>
      <img
        width="120"
        height="106"
        src="../../../../assets/live/wz.png"
        alt=""
      />
      <div class="text1">需要批改？</div>
      <div class="text2">提交练习，24小时内获得详细反馈！</div>
      <el-button class="Button" type="primary" round @click="submit"
        >去提交</el-button
      >
    </div>
    <LiveDialog ref="live" />
  </div>
</template>

<script>
import LiveDialog from '../../components/XZliveDialog'
export default {
  data() {
    return {}
  },
  components: {
    LiveDialog
  },
  methods: {
    submit() {
      this.$refs.live.open()
    },
    toCourse(v) {
      this.$router.replace({
        name: 'IELTScourse',
        query: {
          ...this.$route.query,
          cateMain: 102,
          cateSub: 122
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.boxShow {
  width: 952px;
  height: 146px;
  background: #f5f6fa;
  border-radius: 10px;
  position: relative;
  img {
    position: absolute;
    right: 160px;
    top: 19px;
  }
  .text1 {
    font-size: 16px;
    font-weight: bold;
    color: #1f1f40;
    position: absolute;
    right: 195px;
    top: 31px;
  }
  .text2 {
    font-size: 14px;
    font-weight: 400;
    color: #1f1f40;
    position: absolute;
    right: 36px;
    top: 64px;
  }
  .Button {
    width: 130px;
    height: 28px;
    background: #3b86ff;
    opacity: 1;
    border-radius: 14px;
    font-size: 14px;

    font-weight: 400;
    color: #ffffff;
    border-color: #3b86ff;
    position: absolute;
    line-height: 0px;
    top: 97px;
    right: 41px;
  }
  .boxContent {
    display: flex;
    position: absolute;
    bottom: 20px;
    .two {
      width: 286px;
      height: 145px;
      border-radius: 10px;
      text-align: center;
      background: #ffffff;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
      margin-left: 28px;
      transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
      &:hover {
        transform: translateY(-8px);
      }
      .title {
        height: 42px;
        background: #ffdfb4;
        border-radius: 10px 10px 0px 0px;
        line-height: 42px;
        font-size: 16px;
        color: #1f1f40;
      }
      .content {
        font-size: 14px;

        font-weight: 400;
        line-height: 22px;
        color: #1f1f40;
        padding-top: 11px;
        position: relative;
        .img {
          position: absolute;
          bottom: -19px;
          right: 3px;
          width: 106px;
          height: 118px;
        }
        .img2 {
          position: absolute;
          bottom: -19px;
          right: 3px;
          width: 107px;
          height: 113px;
        }
        .btn {
          width: 130px;
          height: 28px;
          background: #ffdfb4;
          opacity: 1;
          border-radius: 14px;
          font-size: 14px;

          font-weight: 400;
          color: #1f1f40;
          border-color: #ffdfb4;
          line-height: 1px;
          margin-top: 11px;
        }
      }
    }
  }
}
.header {
  display: flex;
  margin-bottom: 40px;
  .textNew {
    font-size: 26px;

    font-weight: bold;
    color: #1f1f40;
  }
  .text {
    font-size: 16px;

    font-weight: 400;
    line-height: 19px;
    color: #3a3a3a;
    margin-left: 12px;
    line-height: 37px;
  }
}
</style>
