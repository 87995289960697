<template>
  <el-dialog
    :visible.sync="visible"
    width="581px"
    center
    custom-class="body-no-padding"
  >
    <div class="dialog-wrapper">
      <div class="top">
        <p class="title">雅思写作作文批改</p>
        <p class="text">
         练习不知对错，没有方向？自己很满意，却考不到高分？
        </p>
      </div>
      <div class="bottom">
        <div class="left">
          <ul>
            <li class="title">扫码添加微信...</li>
            <li class="text"><span></span>备注“PTEGO雅思写作”</li>
            <li class="text"><span></span>与老师互动，购买作文详细批改服务</li>
            <li class="text"><span></span>领取各类备考文件</li>
          </ul>
        </div>
        <div class="right">
          <div class="images">
            <div class="img-wrapper">
              <img
                src="https://ptegofeifan.oss-accelerate.aliyuncs.com/go/admin_file_1618033526974471277507.jpg"
                alt=""
              />
            </div>
            <span>微信：FeifanEnglish006</span>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      info: {},
    }
  },
  computed: {
   
  },
  props: {},
  methods: {
    open(info) {
      this.info = info
      this.visible = true
    },
  },
}
</script>

<style scoped lang="less">
.dialog-wrapper {
  .top {
    padding: 36px 0 20px 0;
    background: #3b86ff;
    text-align: center;
    border-radius: 10px 10px 0 0;
    border-color: #3b86ff;
    .title {
      font-size: 32px;
      color: #ffffff;
      font-weight: 400;
      margin-bottom: 8px;
      margin-left: 16px;
    }
    .text {
      font-size: 14px;
      color: #9dc3ff;
      margin-bottom: 20px;
    }
    .button {
      > button {
        outline: none;
        border: none;
        width: 114px;
        height: 36px;
        background: #ffffff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        border-radius: 50px;
        font-size: 18px;
        color: #3b86ff;
      }
    }
  }
  .bottom {
    background: #ffffff;
    display: flex;
    border-radius: 10px;
    .left {
      margin-left: 67px;
      margin-top: 37px;
      margin-bottom: 47px;
      .title {
        font-size: 18px;
        font-weight: bold;
        line-height: 24px;
        color: #1f1f40;
        padding-top: 8px;
      }
      .text {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #1f1f40;
        padding-top: 8px;
        span {
          width: 5px;
          height: 5px;
          background: #3b86ff;
          border-radius: 50%;
          display: inline-block;
          margin-bottom: 3px;
          margin-right: 7px;
        }
      }
    }
    .right {
      margin-top: 35px;
      margin-left: 128px;
      color: #1f1f40;
      .images {
        padding-top: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .img-wrapper {
          margin-bottom: 6px;
        }
        > span {
          font-size: 12px;
          color: #8f8f9f;
        }
      }
    }
  }
}
/deep/.el-dialog {
  border-radius: 10px !important;
}
::v-deep .body-no-padding {
  .el-dialog__header {
    padding: 0 !important;
    .el-dialog__close {
      color: #fff;
    }
  }
  .el-dialog__body {
    padding: 0 !important;
  }
}
</style>
