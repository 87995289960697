<template>
  <div class="radio-class" v-loading="loading">
    <p class="title">即将直播</p>
    <ul>
      <CardItem v-for="item in liveList" :key="item.id" :info="item" />
    </ul>

    <p class="title next">往期回看</p>
    <ul>
      <CardItem v-for="item in videoList" :key="item.id" :info="item" />
    </ul>

    <Empty v-if="!liveList.length && !videoList.length" />
  </div>
</template>

<script>
import CardItem from '../../components/cardItem'
import Empty from '@/components/Empty'
import { apiCourseList } from '@/api/IELTScourse'

const TRY_CATE_SUB = [21, 22, 23, 24]

export default {
  components: {
    CardItem,
    Empty
  },
  props: {
    onList: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      loading: false,
      liveList: [],
      videoList: []
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.fetchList()
      }
    },
    list() {
      this.onList(this.list)
    }
  },
  methods: {
    async fetchList() {
      this.loading = true
      const { cateMain, cateSub } = this.$route.query
      const { data } = await apiCourseList({
        cate_main: cateMain,
        cate_sub: cateSub,
        page: 1,
        page_size: 999999
      })

      this.liveList = data.list.filter((m) => m.type === 1)
      this.videoList = data.list.filter((m) => m.type === 2)
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.radio-class {
  width: 100%;
  .title {
    color: #1f1f40;
    font-size: 20px;
    font-weight: bold;
    &.next {
      margin-top: 36px;
    }
  }

  .radio-class-title {
    font-size: 20px;
    font-weight: bold;
    color: #1f1f40;
  }
  .radio-class-goback-title {
    font-size: 20px;
    font-weight: bold;
    color: #1f1f40;
    margin-top: 40px;
  }
  > ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px;
  }
}
</style>
