<template>
  <div>
    <img width="967" height="212" src="../../../../assets/live/baner.png" alt="" />
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
</style>